import React, { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ isPlaying }) => {
  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    // Initialize Web Audio API context
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    gainNodeRef.current = audioContextRef.current.createGain();
    gainNodeRef.current.connect(audioContextRef.current.destination);

    // Create brown noise
    const bufferSize = 10 * audioContextRef.current.sampleRate;
    const noiseBuffer = audioContextRef.current.createBuffer(
      1,
      bufferSize,
      audioContextRef.current.sampleRate
    );
    const output = noiseBuffer.getChannelData(0);

    let lastOut = 0;
    for (let i = 0; i < bufferSize; i++) {
      // Generate brown noise

      // Step 1: Generate white noise
      // Math.random() generates a number between 0 and 1
      // Multiply by 2 and subtract 1 to get a number between -1 and 1
      const whiteNoise = Math.random() * 2 - 1;

      // Step 2: Apply the brown noise formula
      // New output = ((previous output + (0.02 * white noise)) / 1.02)
      // 0.02 and 1.02 are coefficients that determine the "brownness" of the noise
      // Smaller coefficient values will make the noise "browner" (more low frequency content)
      output[i] = (lastOut + 0.02 * whiteNoise) / 1.02;
      lastOut = output[i];

      // Step 3: Amplify the signal
      // Brown noise has less energy in higher frequencies, so we need to amplify it
      // to make it audible across the frequency spectrum
      output[i] *= 3.5; // Adjust amplitude
    }

    // Create a buffer source and set it to loop
    const brownNoise = audioContextRef.current.createBufferSource();
    brownNoise.buffer = noiseBuffer;
    brownNoise.loop = true;
    brownNoise.connect(gainNodeRef.current);
    brownNoise.start(0);

    // Cleanup function
    return () => {
      if (audioContextRef.current.state !== "closed") {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (audioContextRef.current) {
      // Resume or suspend audio context based on isPlaying prop
      if (isPlaying) {
        audioContextRef.current.resume();
      } else {
        audioContextRef.current.suspend();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (gainNodeRef.current) {
      // Set the volume by adjusting the gain value
      gainNodeRef.current.gain.setValueAtTime(
        volume,
        audioContextRef.current.currentTime
      );
    }
  }, [volume]);

  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value));
  };

  return (
    <div className="w-full max-w-md text-center">
      <p className="text-sm text-sigin-text text-center mb-2">
        {isPlaying ? "Brown noise is playing" : "Brown noise is paused"}
      </p>
      <div className="flex items-center justify-center">
        <span className="mr-2 text-sm text-sigin-text">Volume:</span>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="w-full max-w-xs accent-sigin-green"
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
