import React, { useState, useEffect } from "react";

const TypeWriter = ({ text, speed = 50 }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let isMounted = true;
    let i = 0;

    const typeNextCharacter = () => {
      if (isMounted && i < text.length) {
        setDisplayText((prevText) => prevText + text.charAt(i));
        i++;
        setTimeout(typeNextCharacter, speed);
      }
    };

    typeNextCharacter();

    return () => {
      isMounted = false;
    };
  }, [text, speed]);

  return <p className="text-sigin-text text-lg">{displayText}</p>;
};

export default TypeWriter;
