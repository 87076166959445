import React, { useEffect, useState } from "react";

const Timer = ({ duration, isRunning, onDurationChange }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      // Timer finished
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  useEffect(() => {
    setTimeLeft(duration);
  }, [duration]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleDurationChange = (e) => {
    const newDuration = parseInt(e.target.value, 10) * 60;
    onDurationChange(newDuration);
  };

  return (
    <div className="text-center">
      <div className="text-7xl font-bold mb-6 text-sigin-text">
        {formatTime(timeLeft)}
      </div>
      <div className="mb-4">
        <label
          htmlFor="duration"
          className="block text-sm font-medium text-sigin-text mb-2"
        >
          Duration (minutes):
        </label>
        <input
          type="number"
          id="duration"
          min="1"
          max="60"
          value={duration / 60}
          onChange={handleDurationChange}
          className="w-24 px-3 py-2 text-center rounded-full border-2 border-sigin-green focus:border-sigin-purple focus:ring focus:ring-sigin-purple focus:ring-opacity-50"
          disabled={isRunning}
        />
      </div>
    </div>
  );
};

export default Timer;
